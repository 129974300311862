import { sendRequest } from "../../Api";
import PrettyDropdown from "../custom/PrettyDropdown";

export default function UserTypePicker({
  doctorId,
  userType,
  onUserTypeChange,
}) {
  const userTypes = [
    { label: "Developer", value: "developer" },
    { label: "Paying", value: "paying" },
    { label: "Friend", value: "friend" },
  ];
  console.log(userType);
  return (
    <PrettyDropdown
      options={userTypes}
      currentlySelected={userType}
      onChange={async (newType) => {
        await sendRequest(
          "api/userType",
          "PUT",
          JSON.stringify({
            doctorId: doctorId,
            userType: newType,
          })
        );
        onUserTypeChange(doctorId, newType);
      }}
    />
  );
}
