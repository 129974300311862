import { Box, Collapse, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Doctor } from "../../customTypes";
import { DEFAULT_TRANSITION } from "../../utils";
import PrettyDropdown from "../custom/PrettyDropdown";
import useData from "../utils/useData";
import TrialExtensionDatePicker from "./TrialExtensionDatePicker";
import UserTypePicker from "./UserTypePicker";

export default function UserManagement() {
  const { data: doctors, updateData: setDoctors } =
    useData<Doctor[]>("allDoctors");
  const [showPromptCompose, setShowPromptCompose] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState<string | undefined>(
    ""
  );
  const [filteredDoctors, setFilteredDoctors] = useState<Doctor[] | undefined>(
    doctors
  );

  useEffect(() => {
    if (selectedUserType) {
      setFilteredDoctors(
        doctors?.filter((doctor) => doctor.userType === selectedUserType)
      );
    } else {
      setFilteredDoctors(doctors);
    }
  }, [selectedUserType, doctors]);

  const handleUserTypeChange = (doctorId: string, newType: string) => {
    const updatedDoctors = doctors?.map((doctor) =>
      doctor.id === doctorId ? { ...doctor, userType: newType } : doctor
    );
    if (updatedDoctors) {
      setDoctors(updatedDoctors);
    }
  };

  const labels = [
    { label: "Email", width: "30%" },
    { label: "Name", width: "20%" },
    { label: "License", width: "25%" },
    { label: "Type", width: "25%" },
  ];

  const userTypes = [
    { label: "All", value: "" },
    { label: "Developer", value: "developer" },
    { label: "Paying", value: "paying" },
    { label: "Friend", value: "friend" },
  ];

  return (
    <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <Stack
        onClick={() => setShowPromptCompose((prev) => !prev)}
        sx={{
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
          User management
        </Typography>
        <Box
          component="img"
          src="/svg/chevron.svg"
          sx={{
            width: "1.25rem",
            height: "1.25rem",
            ...DEFAULT_TRANSITION,
            transform: showPromptCompose ? "scaleY(-1)" : "none",
          }}
        />
      </Stack>
      <Collapse in={showPromptCompose}>
        <Stack sx={{ marginTop: "2rem", gap: "1rem" }}>
          <Typography>Filter by user type</Typography>
          <PrettyDropdown
            options={userTypes}
            currentlySelected={selectedUserType}
            onChange={(newType) => setSelectedUserType(newType)}
          />
        </Stack>
        <Stack
          sx={{ marginTop: "2rem", flexDirection: "row", marginBottom: "1rem" }}
        >
          {labels.map((label, index) => (
            <Typography
              key={index}
              sx={{ width: label.width, fontWeight: "bold" }}
            >
              {label.label}
            </Typography>
          ))}
        </Stack>
        <Stack sx={{ gap: "2rem" }}>
          {filteredDoctors?.map((doctor, index) => (
            <Stack
              sx={{ flexDirection: "row", alignItems: "center" }}
              key={index}
            >
              <Typography sx={{ width: "30%" }}>{doctor.email}</Typography>
              <Typography sx={{ width: "20%" }}>{doctor.name}</Typography>
              <Stack
                sx={{
                  flexDirection: "row",
                  width: "25%",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <Typography>
                  {doctor.license?.type}{" "}
                  {doctor.license?.stripeCustomerId
                    ? `(Stripe customer id: ${doctor.license.stripeCustomerId})`
                    : ""}
                </Typography>
                {doctor.license?.type === "trial" &&
                  doctor.license.expiryDate && (
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: "2rem",
                        alignItems: "center",
                      }}
                    >
                      <TrialExtensionDatePicker
                        defaultDate={doctor.license.expiryDate}
                        doctorId={doctor.id}
                      />
                    </Stack>
                  )}
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  width: "25%",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <UserTypePicker
                  doctorId={doctor.id}
                  userType={doctor.userType}
                  onUserTypeChange={handleUserTypeChange}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
}
